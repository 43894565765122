import { api, currentCreche } from '~/repositories/api/index.js'

const DemandRepository = {

    countPending () {
        return api().$get(`/${currentCreche().uuid}/demands/pending/count`)
    },

    index (query = {}) {
        return api().$get(`/${currentCreche().uuid}/demands`, query)
    },

    show (demandUuid) {
        return api().$get(`/${currentCreche().uuid}/demands/${demandUuid}`)
    },

    create (payload) {
        return api().$post(`/${currentCreche().uuid}/demands`, payload)
    },

    update (demandUuid, payload) {
        return api().$put(`/${currentCreche().uuid}/demands/${demandUuid}`, payload)
    },

    delete (demandUuid) {
        return api().$delete(`/${currentCreche().uuid}/demands/${demandUuid}`)
    },

    approve (demandUuid, payload) {
        return api().$post(`/${currentCreche().uuid}/demands/${demandUuid}/approve`, payload)
    },

    refuse (demandUuid, payload) {
        return api().$post(`/${currentCreche().uuid}/demands/${demandUuid}/refuse`, payload)
    }
}

export default DemandRepository
